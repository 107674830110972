import { DEL_ITEM_PERMISSION, CONTAINER_FILLING_SPECIFICATION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Specification", "search-by": "Code,Description", "ph-search-by": "Search by Specification, Description", "has-active": false, "has-delete": true, "config-delete": _vm.configDel }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var del = ref.del;
    return [_vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return del(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "Code", attrs: { "base-url": "/container-filling/specification", "data-index": "code", "title": "Specification", "width": 230, "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description" } }), _c("text-field", { key: "NoOfTest", attrs: { "data-index": "noOfTest", "title": "No of Test", "align": "right", "width": 230, "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListSpecification",
  inject: ["can"],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION
      },
      configDel: {
        source: "code",
        sourceLabel: "Specification",
        icon: "delete"
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListSpecification = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-specification" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.specifications", "api-url": _vm.apiUrl, "create-route": "/container-filling/specification/create", "edit-route": "/container-filling/specification/:id" } }, [_c("list-specification")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListSpecification
  },
  data() {
    return {
      page: CONTAINER_FILLING_SPECIFICATION,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "specification",
          title: "Specification",
          path: "/container-filling/specification"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
